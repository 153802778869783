<template>
  <div class="flex items-center mb-1 ml-2 left-side">
    <button
      class="px-4 py-3 text-white rounded-full filter-dropshadow focus:outline-none focus:text-purple-600 hover:bg-gray-100 hover:text-purple-600"
      @click.prevent="togglePlayState"
    >
      <fa-icon
        v-if="playerState === 'paused'"
        icon="play"
      />
      <fa-icon
        v-else-if="playerState === 'blocked'"
        icon="spinner"
        spin
      />
      <fa-icon
        v-else
        icon="pause"
      />
    </button>

    <button
      v-if="false"
      class="px-4 py-3 text-white rounded-full filter-dropshadow focus:outline-none focus:text-purple-600 hover:bg-gray-100 hover:text-purple-600"
      @click.prevent
    >
      <fa-icon icon="volume-high" />
    </button>

    <div class="px-2 py-1 font-mono text-xs text-gray-100 cursor-default filter-dropshadow">
      {{ currentTimecode }} / {{ durationFormatted }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'ViewerLeftControls',

  props: ['playerState', 'currentTimecode', 'durationFormatted'],

  methods: {
    togglePlayState () {
      this.$emit('toggle-play')
    }
  }
}
</script>
